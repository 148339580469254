import React, { useEffect, useState } from "react";

import LieferantenqualifizierungForm from "./form";

const Lieferantenqualifizierung = () => {

    const [orgId, setOrgId] = useState(undefined);
    const [clientId, setClientId] = useState(undefined);
    const [formType, setFormType] = useState(undefined);
    const [urlParsed, setUrlParsed] = useState(false);

    useEffect(() => {
        if (urlParsed === false) {
            const url = new URLSearchParams(window.location.search.slice(1));
            const isValidURL = url.has("org_id") && url.has("client_id") && url.has("form_type");
            if (isValidURL) {
                setOrgId(url.get('org_id'));
                setClientId(url.get('client_id'));
                setFormType(url.get('form_type'));
            }
            setUrlParsed(true);
        }
    })

    const isValid = orgId !== undefined && clientId !== undefined && formType !== undefined;
    return (
        <section class="section">
            <div class="container">
                <h1 className="title">Fragebogen zur Lieferantenqualifizierung</h1>
                {isValid ? (
                    <LieferantenqualifizierungForm orgId={orgId} clientId={clientId} formType={formType} />
                ) : null}
            </div>
        </section>
    )
}

export default Lieferantenqualifizierung;