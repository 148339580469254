import React from "react";
import ReactDOM from "react-dom";

import Lieferantenqualifizierung from "./Features/Lieferantenqualifizierung";

class App extends React.Component {
    render() {
        return <Lieferantenqualifizierung />;
    }
}

var mountNode = document.getElementById("app");
ReactDOM.render(<App />, mountNode);