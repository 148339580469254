import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import FORM_DEF from "./definition";

const HorizontalField = ({ label, children }) => {
    return (
        <div className="field">
            <label>{label}</label>
            <div className="control">
                {children}
            </div>
        </div>
    )
}

function saveFormData(orgId, clientId, formType, formData, successCallBack) {
    const attributes = {
        form_data: {
            field_values: formData
        },
        form_definition: FORM_DEF
    }
    const url = `https://backend.lieferantenqualifizierung.de/forms/?organization=${orgId}&client_id=${clientId}&form_type=${formType}`;
    fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(attributes),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
    }).then(response => {
        response.json().then(json => {
            successCallBack(json["pdf_url"]);
        });
    })

    // then(function(response) { console.log(response); return response.json(); }).then(function (response) {
    //     successCallBack(response.pdf_url);
    // });
}

const getFormTypeJSX = (form_field_def, values) => {
    if (form_field_def["type"] === "check") {
        return (
            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <Field
                            name={form_field_def["name"]}
                            type="checkbox"
                            className="checkbox"
                            style={{ fontSize: "72px", marginRight: "5px" }}
                        />{" "}
                        {form_field_def["placeholder"]}
                    </label>
                </div>
            </div>
        )
    }
    if (form_field_def["type"] === "select") {
        return (
            <HorizontalField label={form_field_def["placeholder"]}>
                <div className="select">
                    <Field
                        name={form_field_def["name"]}
                        label={form_field_def["placeholder"]}
                        component="select"
                        className="select"
                    >
                        {form_field_def["options"].map((option) => {
                            return <option value={option}>{option}</option>
                        })}
                    </Field>
                </div>
            </HorizontalField>
        )

    }

    if (form_field_def["type"] === "textarea") {
        return (
            <HorizontalField label={form_field_def["placeholder"]}>
                <Field
                    name={form_field_def["name"]}
                    type="text"
                    className="textarea"
                />
            </HorizontalField>
        )
    }

    if (form_field_def["type"] === "text" || form_field_def["type"] === "date") {
        return (
            <HorizontalField label={form_field_def["placeholder"]}>
                <Field
                    name={form_field_def["name"]}
                    type="text"
                    className="input"
                />
            </HorizontalField>
        )
    }
}

const getFormFieldWithChildrenJSX = (form_field_def, values) => {
    const currentValue = values[form_field_def["name"]];
    let showChildren = true;
    if (form_field_def["type"] === "check") {
        showChildren = currentValue;
    }
    const result = (
        <React.Fragment>
            {getFormTypeJSX(form_field_def, values)}
            {form_field_def["children"] !== undefined && showChildren === true ? (
                <div className={form_field_def["type"] === "header" ? "" : "box"}>
                    {form_field_def["children"].map((form_field_child) => {
                        return getFormFieldWithChildrenJSX(form_field_child, values);
                    })}
                </div>
            ) : null}
        </React.Fragment>
    )

    if (form_field_def["type"] === "header") {
        return (
            <div className="panel">
                <div class="panel-heading">{form_field_def["label"]}</div>
                <div class="panel-block">
                    {result}
                </div>
            </div>
        )
    }
    return result;

}

const getInitialValues = (formDef, initialValues) => {
    formDef.forEach((form_def) => {
        if (form_def["name"] !== undefined) {
            initialValues[form_def["name"]] = form_def["initialValue"];
        }
        if (form_def["children"] !== undefined) {
            getInitialValues(form_def["children"], initialValues)
        }
    })

    return initialValues;
}

const LieferantenqualifizierungForm = ({ orgId, clientId, formType }) => {
    const [successfulSubmitted, setSuccessfulSubmitted] = useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState("");
    let initialValues = {};

    initialValues = getInitialValues(FORM_DEF, initialValues);

    if (successfulSubmitted) {
        return (
            <div>
                <h2>Ihre Daten wurden erfolgreich übertragen.</h2>
                <a href={pdfFileUrl}>Ihr PDF-Dokument</a>
            </div>
        )
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    console.log(values);
                    saveFormData(orgId, clientId, formType, values, (url) => {
                        setSuccessfulSubmitted(true);
                        setPdfFileUrl(url);
                    })
                }}
                render={props => (
                    <Form>
                        <div className="form">
                            {FORM_DEF.map((form_field) => {
                                return getFormFieldWithChildrenJSX(form_field, props.values);
                            })}
                            <button
                                type="submit"
                                className="button is-large is-success"
                            >
                                Übertragen
                            </button>
                        </div>
                    </Form>
                )}
            />
        </div>
    )
}

export default LieferantenqualifizierungForm;