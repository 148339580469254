const FORM_DEF = [{
        label: "Fragen zur internen Qualitätssicherung",
        type: "header",
        children: [{
                name: "euGDP8",
                placeholder: "Führen Sie regelmäßig Selbstinspektionen nach Kap. 8 EU-GDP durch?",
                type: "check",
                initialValue: false
            },
            {
                name: "lastInspection",
                placeholder: "Datum letzte Inspektion",
                type: "date",
                initialValue: ""
            },
            {
                name: "clearDescription",
                placeholder: "Sind alle Abläufe durch SOP klar beschrieben?",
                type: "check",
                initialValue: false,
                children: [{
                        name: "sopIndex",
                        placeholder: "SOP Inhaltsverzeichnis/Index",
                        type: "textarea",
                        initialValue: ""
                    },
                    {
                        name: "sopHygiene",
                        placeholder: "SOP für Hygiene + Putzpläne",
                        type: "check",
                        initialValue: false
                    },
                    {
                        name: "sopReklamation",
                        placeholder: "SOP für Reklamationen",
                        type: "check",
                        initialValue: false
                    },
                    {
                        name: "sopAktuell",
                        placeholder: "SOP für das Aktuellhalten der SOP Sammlung",
                        type: "check",
                        initialValue: false
                    },
                    {
                        name: "sopDoku",
                        placeholder: "Wie lange heben Sie die Dokumentation auf",
                        type: "text",
                        initialValue: ""
                    },
                    {
                        name: "sopManager",
                        placeholder: "Wer genehmigt die SOP",
                        type: "select",
                        options: ["Geschäftsführer", "QP", "RP", "Andere"],
                        initialValue: ""
                    },
                    {
                        name: "inspection",
                        placeholder: "Behördliche Inspektion innerhalb der letzten 24 Monate",
                        type: "check",
                        initialValue: false,
                        children: [{
                                name: "inspectionDate",
                                placeholder: "Inspektionsdatum",
                                type: "date",
                                initialValue: ""
                            },
                            {
                                name: "inspectionName",
                                placeholder: "Behördenname",
                                type: "text",
                                initialValue: ""
                            }
                        ]
                    }
                ]
            },
            {
                name: "temperatureCheck",
                placeholder: "Temperaturgeführtes oder klimatisiertes Lager",
                type: "check",
                initialValue: false,
                children: [{
                        name: "temperaturePermanentCheck",
                        placeholder: "Funktionsfähige und permanente Temperaturüberwachung",
                        type: "check",
                        initialValue: false
                    },
                    {
                        name: "temperatureCheckRecord",
                        placeholder: "Temperaturverlauf wird aufgezeichnet",
                        type: "select",
                        initialValue: false,
                        options: ["manuell", "automatisch"]
                    },
                    {
                        name: "temperatureCalibration",
                        placeholder: "Temperaturüberwachungsanlage kalibriert",
                        type: "check",
                        initialValue: false
                    }
                ]
            },
            {
                name: "humidityControl",
                placeholder: "Luftfeuchtigkeitsüberwachung",
                type: "check",
                initialValue: false,
                children: [{
                    name: "humidityControlDocumentation",
                    placeholder: "Werden die Ergebnisse dokumentiert?",
                    type: "check",
                    initialValue: false
                }]
            },
            {
                name: "pestControl",
                placeholder: "Unterhalten Sie ein Schädlingsbekämpfungssystem (Pest-Control-System / SBS)?",
                type: "select",
                initialValue: false,
                options: ["Nein", "Ratten/Mäuse", "Fliegen/Insekten", "Kakerlake", "Andere"],
                children: [{
                    name: "pestControlCheck",
                    placeholder: "Wie oft wird die Effektivität des SBS validiert?",
                    type: "select",
                    initialValue: false,
                    options: ["< 1 Monat", "< 3 Monate", "> 3 Monate"]
                }]
            },
            {
                name: "accessControl",
                placeholder: "Haben Sie ein Zutrittskontrollsystem, ggf. Alarmgesichert",
                type: "check",
                initialValue: false
            },
            {
                name: "education",
                placeholder: "Wie oft werden Ihre Mitarbeiter geschult?",
                type: "select",
                initialValue: false,
                options: ["< 1 Jahre", "< 2 Jahre", "> 2 Jahre"]
            },
            {
                name: "educationGDP",
                placeholder: "Sind Ihre Mitarbeiter in GDP und AmHandelsV geschult?",
                type: "check",
                initialValue: false,
                children: [{
                        name: "educationCoolingProducts",
                        placeholder: "Schulung im Umgang mit Kühlprodukten oder Ähnlichem",
                        type: "check",
                        initialValue: false
                    },
                    {
                        name: "educationCoolingProductsDocumentation",
                        placeholder: "Wird dies dokumentiert?",
                        type: "check",
                        initialValue: false
                    }
                ]
            },
            {
                name: "wawi",
                placeholder: "Unterhalten Sie ein validiertes WaWi-System",
                type: "check",
                initialValue: false
            },

        ]
    },
    {
        label: "Qualifizierung des Lieferanten zum Bezug pharmazeutischer Produkte",
        type: "header",
        children: [{
                name: "customerList",
                placeholder: "Führen Sie eine Liste Ihrer zugelassenen Lieferanten und Kunden?",
                type: "check",
                initialValue: false
            },
            {
                name: "customerLicenses",
                placeholder: "Haben Sie von allen Handelspartner die nötigen Lizenzen und Erlaubnisse vorliegen?",
                type: "check",
                initialValue: false
            },
            {
                name: "customerGerman",
                placeholder: "Kommen alle Ihre Handelswaren von deutschen Herstellern oder Großhändlern?",
                type: "check",
                initialValue: false
            },
            {
                name: "customerAMG",
                placeholder: "Wie stellen Sie sicher, dass Sie Ihre Waren nur von Lieferanten beziehen, die über eine Erlaubnis nach §52a AMG oder §§13AMG verfügen?",
                type: "select",
                initialValue: false,
                options: ["Anforderung der Erlaubnisse und Lizenzen", "Lieferantenaudit", "Anderes"]
            },
            {
                name: "customerNoIllegalMedicament",
                placeholder: "Wie stellen Sie sicher, dass keine gefälschten oder anderswie nicht verkehrsfähigen Arzneimittel in die Lieferkette gelangen?",
                type: "text",
                initialValue: ""
            },
            {
                name: "customerCallback",
                placeholder: "Unterhalten Sie einen GDPgerechten Rückrufplan?",
                type: "check",
                initialValue: false
            }
        ]
    },
    {
        label: "Qualifizierung der Medikamentenlogistik",
        type: "header",
        children: [{
                name: "transportQualified",
                placeholder: "Ist Ihr Transportunternehmen für den Arzneimitteltransport qualifiziert?",
                type: "check",
                initialValue: false,
                children: [{
                    name: "transportGDPContract",
                    placeholder: "Wurde ein GDP konformer Qualitätsstandardvertrag oder ein Technical Agreement (TA) abgeschlossen?",
                    type: "check",
                    initialValue: false
                }]
            },
            {
                name: "transportPackage",
                placeholder: "Ist Ihr Transportverpackung für die Arzneimittellogistik geeignet?",
                type: "check",
                initialValue: false
            },
            {
                name: "transportDividedAreas",
                placeholder: "Unterhalten Sie getrennte Bereiche für Wareneingang, -quarantäne, -ausgang, Sperrlager?",
                type: "check",
                initialValue: false
            },
            {
                name: "transportRetoure",
                placeholder: "Haben Sie ein Retouren oder Reklamationsmanagement?",
                type: "check",
                initialValue: false
            },
            {
                name: "entryControl",
                placeholder: "Kontrollieren Sie alle eingehenden Produkte?",
                type: "check",
                initialValue: false
            },
            {
                name: "entryFake",
                placeholder: "Prüfen Sie alle Ihre Produkte auf Fälschungen?",
                type: "check",
                initialValue: false
            },
            {
                name: "oldProductManagement",
                placeholder: "Wie werden ausgesonderte Produkte verwaltet und entsorgt?",
                type: "text",
                initialValue: ""
            },
            {
                name: "callBackTracking",
                placeholder: "Wie können Sie zurückgerufene Arzneimittel zurückverfolgen?",
                type: "text",
                initialValue: ""
            }
        ]
    },
    {
        label: "Erlaubnis zur Durchführung von Audits und Kontrollen",
        type: "header",
        children: [{
            name: "audits",
            placeholder: "Besitzen Sie eine Erlaubnis zur Durchführung von Audits und Kontrollen?",
            type: "check",
            initialValue: false
        }]
    }

]

export default FORM_DEF;